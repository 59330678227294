import React from 'react';
import Helmet from 'react-helmet';

import Ticker from '../Ticker';

import { Wrapper, PageWrapper, GlobalStyle } from './style';

import Favicon from '../../images/favicon.png';

const Layout = ({ children, pageContext }) => {
  const { metaData } = pageContext;

  return (
    <Wrapper>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta property="og:title" content={metaData.title} />
        <meta
          property="og:description"
          content={metaData.description.description}
        />
        <meta property="og:type" content="website" />
        {metaData.image ? (
          <meta property="og:image" content={metaData.image.fluid.src} />
        ) : null}
        <link href={Favicon} rel="shortcut icon" />
      </Helmet>
      <GlobalStyle />
      <PageWrapper>{children}</PageWrapper>
      <Ticker text={pageContext.ticker} />
    </Wrapper>
  );
};

export default Layout;
