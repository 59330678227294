import React, { createContext, useReducer } from 'react';

const initialState = {
  activeId: null,
};

// Context
const State = createContext(initialState);
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case 'setActiveId':
      return {
        ...state,
        activeId: action.activeId,
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const ZoomedState = {
  State,
  Dispatch,
  Provider,
};
