import React, { useMemo, useEffect } from 'react';

import { Wrapper, Svg } from './style';

import useCounter from '../../hooks/useCounter';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Ticker = ({ text }) => {
  const { count } = useCounter(Math.floor(Math.random() * 100));
  const { width, height } = useWindowDimensions();

  const d = useMemo(() => {
    return segments
      .map((s) => {
        const points = s.points.map((p) => {
          return `${(p[0] / 100) * width},${(p[1] / 100) * height}`;
        });
        return `${s.action}${points.join(' ')}`;
      })
      .join(' ');
  }, [width, height]);

  const textOffset = count % 100;

  return (
    <Wrapper>
      <Svg
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path d={d} fill="none" id="curvedPath" stroke="none"></path>
        </defs>
        <text>
          <textPath xlinkHref="#curvedPath" startOffset={`${textOffset}%`}>
            {text}
          </textPath>
        </text>
      </Svg>
    </Wrapper>
  );
};

const segments = [
  {
    action: 'M',
    points: [[-10.0656119, 16.4803738]],
  },
  {
    action: 'C',
    points: [
      [-10.0656119, 16.4803738],
      [-10.6051962, 31.5388934],
      [4.95281801, 31.5388934],
    ],
  },
  {
    action: 'C',
    points: [
      [20.5108322, 31.5388934],
      [21.4101394, 13.7088671],
      [14.0358205, 12.1383467],
    ],
  },
  {
    action: 'C',
    points: [
      [6.66150165, 10.5678262],
      [4.4132337, 16.5727574],
      [4.68302585, 24.0558254],
    ],
  },
  {
    action: 'C',
    points: [
      [4.95281801, 31.5388934],
      [12.596929, 37.6362081],
      [21.5000701, 36.4352219],
    ],
  },
  {
    action: 'C',
    points: [
      [30.4032112, 35.2342357],
      [34.5400242, 20.2680996],
      [33.4608556, 6.50294978],
    ],
  },
  {
    action: 'C',
    points: [
      [32.381687, -7.26220005],
      [37.77753, -11.7889943],
      [44.3424724, -9.38702184],
    ],
  },
  {
    action: 'C',
    points: [
      [50.9074148, -6.98504939],
      [52.7959599, 9.92114135],
      [45.8712946, 19.990949],
    ],
  },
  {
    action: 'C',
    points: [
      [38.9466294, 30.0607566],
      [33.011202, 36.3428384],
      [38.1372529, 40.5924819],
    ],
  },
  {
    action: 'C',
    points: [
      [43.2633038, 44.8421255],
      [55.0442279, 36.619989],
      [50.4577613, 28.305469],
    ],
  },
  {
    action: 'C',
    points: [
      [45.8712946, 19.990949],
      [30.6730033, 18.0508943],
      [27.1657053, 26.642565],
    ],
  },
  {
    action: 'C',
    points: [
      [23.6584073, 35.2342357],
      [23.4785459, 41.7010846],
      [27.9750818, 47.3364815],
    ],
  },
  {
    action: 'C',
    points: [
      [32.4716177, 52.9718784],
      [36.5185, 60.8244807],
      [31.8421027, 66.5522612],
    ],
  },
  {
    action: 'C',
    points: [
      [27.1657053, 72.2800416],
      [15.474712, 74.7743976],
      [6.30177878, 68.3999323],
    ],
  },
  {
    action: 'C',
    points: [
      [-2.87115445, 62.0254669],
      [8.37018529, 53.6185633],
      [14.9351277, 53.6185633],
    ],
  },
  {
    action: 'C',
    points: [
      [21.5000701, 53.6185633],
      [26.3563289, 55.9281522],
      [27.1657053, 63.041686],
    ],
  },
  {
    action: 'C',
    points: [
      [27.9750818, 70.1552198],
      [27.8851511, 76.8068359],
      [18.4424257, 93.9901773],
    ],
  },
  {
    action: 'C',
    points: [
      [8.99970031, 111.173519],
      [25.9066753, 114.961244],
      [32.0219641, 103.228533],
    ],
  },
  {
    action: 'C',
    points: [
      [38.1372529, 91.4958213],
      [34.0004399, 78.8392741],
      [15.5646427, 83.2736848],
    ],
  },
  {
    action: 'C',
    points: [
      [-2.87115445, 87.7080955],
      [-2.87115445, 101.103711],
      [7.11115524, 104.70667],
    ],
  },
  {
    action: 'C',
    points: [
      [17.0934649, 108.309628],
      [25.0972988, 98.7017386],
      [27.7952203, 90.0176844],
    ],
  },
  {
    action: 'C',
    points: [
      [30.4931419, 81.3336301],
      [35.3494006, 68.3999323],
      [42.9035809, 68.3999323],
    ],
  },
  {
    action: 'C',
    points: [
      [50.4577613, 68.3999323],
      [52.1664449, 69.6933021],
      [54.5945743, 74.1277127],
    ],
  },
  {
    action: 'C',
    points: [
      [57.0227037, 78.5621234],
      [51.177207, 81.4260137],
      [47.7598397, 81.4260137],
    ],
  },
  {
    action: 'C',
    points: [
      [44.3424724, 81.4260137],
      [32.7414098, 83.4584519],
      [33.1011327, 68.1227816],
    ],
  },
  {
    action: 'C',
    points: [
      [33.4608556, 52.7871113],
      [54.8643664, 51.0318238],
      [54.8643664, 51.0318238],
    ],
  },
  {
    action: 'C',
    points: [
      [54.8643664, 51.0318238],
      [67.3647362, 48.9993855],
      [69.5230734, 59.715878],
    ],
  },
  {
    action: 'C',
    points: [
      [71.6814107, 70.4323705],
      [79.865106, 71.6333567],
      [85.5307412, 68.2151652],
    ],
  },
  {
    action: 'C',
    points: [
      [91.1963764, 64.7969736],
      [94.7936052, 51.2165909],
      [87.4192863, 46.9669473],
    ],
  },
  {
    action: 'C',
    points: [
      [80.0449674, 42.7173037],
      [72.4008564, 39.7610299],
      [70.9619649, 33.2017975],
    ],
  },
  {
    action: 'C',
    points: [
      [69.5230734, 26.642565],
      [70.4223806, 22.3929214],
      [80.7644132, 19.4366476],
    ],
  },
  {
    action: 'C',
    points: [
      [91.1064457, 16.4803738],
      [92.0956836, 23.7786747],
      [91.016515, 27.2892499],
    ],
  },
  {
    action: 'C',
    points: [
      [89.9373464, 30.799825],
      [80.2248289, 40.9620162],
      [67.5445976, 22.2081543],
    ],
  },
  {
    action: 'C',
    points: [
      [54.8643664, 3.45429244],
      [64.6668147, -4.95261116],
      [75.0987779, -4.95261116],
    ],
  },
  {
    action: 'C',
    points: [
      [85.5307412, -4.95261116],
      [84.8112955, 10.7525934],
      [77.1671845, 12.1383467],
    ],
  },
  {
    action: 'C',
    points: [
      [69.5230734, 13.5241],
      [59.9004866, 13.0621823],
      [57.3824265, 4.56289511],
    ],
  },
  {
    action: 'C',
    points: [
      [54.8643664, -3.93639204],
      [58.821318, -8.83272051],
      [66.3754983, -8.83272051],
    ],
  },
  {
    action: 'C',
    points: [
      [73.9296786, -8.83272051],
      [74.1994708, 9.45922357],
      [70.8720342, 19.4366476],
    ],
  },
  {
    action: 'C',
    points: [
      [67.5445976, 29.4140717],
      [59.9004866, 36.7123726],
      [47.4001168, 32.0008112],
    ],
  },
  {
    action: 'C',
    points: [
      [34.8997471, 27.2892499],
      [25.367091, 16.9422916],
      [17.3632571, 7.05725112],
    ],
  },
  {
    action: 'C',
    points: [
      [9.35942318, -2.82778937],
      [-5.29928383, -9.4794054],
      [-11.5944341, 2.34568976],
    ],
  },
  {
    action: 'C',
    points: [
      [-17.8895843, 14.1707849],
      [-21.3069516, 21.5614694],
      [-13.1232563, 33.7560988],
    ],
  },
  {
    action: 'C',
    points: [
      [-4.93956096, 45.9507282],
      [10.708384, 56.39007],
      [29.6837654, 58.052974],
    ],
  },
  {
    action: 'C',
    points: [
      [48.6591469, 59.715878],
      [61.699101, 61.1940149],
      [63.5876461, 48.8146184],
    ],
  },
  {
    action: 'C',
    points: [
      [65.4761911, 36.4352219],
      [61.4293088, 31.4465099],
      [54.5945743, 31.7236606],
    ],
  },
  {
    action: 'C',
    points: [
      [47.7598397, 32.0008112],
      [36.6983614, 49.0917691],
      [54.8643664, 62.1178505],
    ],
  },
  {
    action: 'C',
    points: [
      [73.0303714, 75.1439319],
      [92.2755451, 51.8632758],
      [102.797439, 47.4288651],
    ],
  },
  {
    action: 'C',
    points: [
      [113.319333, 42.9944544],
      [121.952682, 53.6185633],
      [119.614483, 68.8618501],
    ],
  },
  {
    action: 'C',
    points: [
      [117.276285, 84.1051368],
      [99.3800718, 89.7405337],
      [91.64603, 85.1213559],
    ],
  },
  {
    action: 'C',
    points: [
      [83.9119883, 80.5021781],
      [79.5053831, 71.5409732],
      [84.2717112, 64.7969736],
    ],
  },
  {
    action: 'C',
    points: [
      [89.0380392, 58.052974],
      [107.293975, 57.0367549],
      [108.373144, 71.9105074],
    ],
  },
  {
    action: 'C',
    points: [
      [109.452312, 86.7842599],
      [113.049541, 99.6255742],
      [94.0741594, 104.059985],
    ],
  },
  {
    action: 'C',
    points: [
      [75.0987779, 108.494396],
      [63.4077846, 102.858999],
      [60.9796552, 93.0663417],
    ],
  },
  {
    action: 'C',
    points: [
      [58.5515259, 83.2736848],
      [61.699101, 76.160151],
      [70.6921728, 76.160151],
    ],
  },
  {
    action: 'C',
    points: [
      [79.6852446, 76.160151],
      [82.1133739, 91.5882048],
      [76.5376694, 97.4083688],
    ],
  },
  {
    action: 'C',
    points: [
      [70.9619649, 103.228533],
      [58.6414566, 109.880149],
      [48.3893547, 98.1474373],
    ],
  },
  {
    action: 'C',
    points: [
      [38.1372529, 86.4147257],
      [44.5223339, 83.1813012],
      [46.1410868, 71.4485896],
    ],
  },
  {
    action: 'C',
    points: [
      [47.7598397, 59.715878],
      [40.655313, 42.9020708],
      [26.3563289, 50.4775224],
    ],
  },
  {
    action: 'C',
    points: [
      [12.0573447, 58.052974],
      [27.3455668, 71.3562061],
      [36.6084307, 65.0741243],
    ],
  },
  {
    action: 'C',
    points: [
      [45.8712946, 58.7920425],
      [46.3209482, 48.4450842],
      [40.7452437, 38.3752766],
    ],
  },
  {
    action: 'C',
    points: [
      [35.1695392, 28.305469],
      [25.9066753, 26.5501814],
      [16.104227, 32.0931948],
    ],
  },
  {
    action: 'C',
    points: [
      [6.30177878, 37.6362081],
      [3.87364939, 49.0917691],
      [-8.71665111, 44.2878242],
    ],
  },
  {
    action: 'C',
    points: [
      [-21.3069516, 39.4838793],
      [-19.9579908, 32.0008112],
      [-19.9579908, 32.0008112],
    ],
  },
];

export default Ticker;
