import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    overflow: ${(props) => (props.lockedScroll ? 'hidden' : '')};
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4rem;
    overflow: ${(props) => (props.lockedScroll ? 'hidden' : '')};
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  p, h1, h2, h3, h4, h5, h6, ul {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 1.4rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h6 {
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }

  h2 {
    margin-top: 2.8rem;
  }

  // h6 {
  //   margin-bottom: 0;
  // }

  // h6 + ul {
  //   margin-top: 0;
  // }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0 0 0 1.4rem;
    text-indent: -1.4rem;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  a {
    background: rgba(255,255,0.8);
    color: inherit;
    text-decoration: none;

    &:hover {
      /* font-style: italic; */
      text-decoration: underline;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const PageWrapper = styled.div`
  /* border-top: #000 solid 2px; */
  padding: 5vw 15vw 5vw 30vw;
  position: relative;
  transition: margin 0.8s ease-in-out;
  z-index: 1;

  @media screen and (max-width: 900px) {
    padding: 2.8rem;
  }

  @media screen and (max-width: 600px) {
    padding: 1.4rem;
  }
`;
