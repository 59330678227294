import React from 'react';
import { SlideshowState } from './SlideshowState';
import { ZoomedState } from './ZoomedState';

const providers = [<SlideshowState.Provider />, <ZoomedState.Provider />];

const Store = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => React.cloneElement(parent, { children }),
    initial
  );

export { Store, SlideshowState, ZoomedState };
