import React, { createContext, useReducer } from 'react';

const initialState = {
  isOpen: true,
  activeIndex: 0,
  artworks: [],
};

// Context
const State = createContext(initialState);
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'setIsOpen':
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case 'setActiveIndex':
      return {
        ...state,
        activeIndex: action.activeIndex,
      };
    case 'setArtworks':
      return {
        ...state,
        artworks: action.artworks,
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const SlideshowState = {
  State,
  Dispatch,
  Provider,
};
