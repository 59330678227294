import styled from 'styled-components';

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

export const Svg = styled.svg`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  text {
    fill: #000;
    font-family: Times, 'Times New Roman', serif;
    font-size: 1rem;
    letter-spacing: 0.02rem;
    text-anchor: start;
  }
`;
