import { useState, useRef, useEffect } from 'react';

export default (initialValue) => {
  const [count, setCount] = useState(initialValue);

  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;
      setCount((prevCount) => (prevCount + deltaTime * 0.0002) % 100);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return { count };
};
